import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { RecommendationsServiceProduct } from 'src/services/recommendations/types';
import type { TrackingProductData } from 'src/tracking/common/products';

export const mapProductToTrackingProduct = (
  {
    free_domestic_delivery: freeDomesticDelivery,
    new: isNew,
    on_sale: onSale,
    prices,
    sale_percentage: salePercentage,
    ...product
  }: RecommendationsServiceProduct,
  currency: CurrencyCode,
) =>
  ({
    isNew,
    onSale,
    salePercentage,
    freeDomesticDelivery,
    price: prices.find((prices) => prices.currency === currency),
    ...product,
  } as TrackingProductData);
