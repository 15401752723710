import { useEffect, useState } from 'react';
import type { RichRelevancePlacement } from '@noths/polaris-client-recommendations';
import {
  getRecommendations,
  initRecommendations,
  sendRecommendationsPageData,
  setDepartmentPage,
  setHomepage,
} from '@noths/polaris-client-recommendations';
import { getUserAttributes } from '@noths/polaris-client-user-configuration';
import getConfig from 'next/config';

import { isProdLike } from 'src/environment';
import type { NextConfig } from 'src/types/NextConfig';
import type { PageConfiguration, PageContent, PageMetadata } from 'src/types/PageProps';
import type { SerializedUISection } from 'src/types/Section';

const { publicRuntimeConfig } = getConfig() as NextConfig;

const getPlacementTypesFromSections = (sections: SerializedUISection[]) =>
  sections
    .map(({ props }) => props.placementType as string)
    .filter((placementType) => !!placementType);

export interface UseRecommendationsArgs {
  pageConfig: PageConfiguration;
  pageContent: PageContent;
  pageMetadata: PageMetadata;
}

export const useRecommendations = ({
  pageConfig,
  pageContent,
  pageMetadata,
}: UseRecommendationsArgs) => {
  const [placementData, setPlacementData] = useState<RichRelevancePlacement[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (pageMetadata.recommendationsSessionId) {
      getUserAttributes()
        .then((attributes) =>
          initRecommendations({
            apiKey: publicRuntimeConfig.RICH_RELEVANCE_API_KEY,
            env: isProdLike() ? 'production' : 'qa',
            hasDisabledCookies: !pageMetadata.functionalCookiesConsented,
            sessionId: pageMetadata.recommendationsSessionId,
            userId: attributes?.sub,
          }),
        )
        .then(async () => {
          if (pageConfig.contentType === 'homepage') {
            setHomepage();
          } else if (pageContent.departmentId) {
            setDepartmentPage(pageContent.departmentId);
          }

          const placementTypes = getPlacementTypesFromSections(pageContent.sections);

          if (placementTypes.length) {
            const placementData = await getRecommendations(placementTypes);
            setPlacementData(placementData);
          } else {
            sendRecommendationsPageData();
          }
        })
        .catch((e) => {
          console.warn(`Could not load recommendations on ${pageConfig.contentType}: ${e.message}`);
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, []);

  return {
    getPlacementData: (placementType: string) =>
      placementData.find((pd) => pd.placement_name === placementType) || null,
    loaded,
  };
};
