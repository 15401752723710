import React from 'react';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import type { ProductCarouselRecommendationsProps } from 'src/components/organisms/ProductCarouselRecommendations/ProductCarouselRecommendations';
import { ProductCarouselRecommendations } from 'src/components/organisms/ProductCarouselRecommendations/ProductCarouselRecommendations';
import { mapProductToTrackingProduct } from 'src/content/sections/productCarouselRecommendations/mapProductToTrackingProduct';
import { trackCarouselNavigation } from 'src/tracking/common/carousel';
import { trackProductClick, trackProductImpression } from 'src/tracking/common/products';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './ProductCarouselRecommendationsSection.style';

export interface ProductCarouselRecommendationsSectionProps
  extends ProductCarouselRecommendationsProps {
  hasDisabledCookies: boolean;
  trackingPosition?: number;
  trackingTitle: string;
}

export const ProductCarouselRecommendationsSection = ({
  currency,
  placementType,
  trackingPosition,
  ...restOfProps
}: ProductCarouselRecommendationsSectionProps) => {
  const trackingContext: Omit<TrackingContext, 'trackingTitle'> = {
    trackingContentType: 'Rich Relevance',
    trackingPosition,
  };

  return (
    <SectionContainer columnsXL={10} maxWidth="max" wrapperStyles={styles.wrapper}>
      <ProductCarouselRecommendations
        currency={currency}
        onNavigateCarousel={(swiped, control, { numberOfItems, strategy }) => {
          const trackingDirection = control === 'next' ? 'next' : 'previous';

          trackCarouselNavigation(swiped, trackingDirection, {
            ...trackingContext,
            numberOfItems,
            trackingTitle: `${strategy} : ${placementType}`,
          });
        }}
        onProductClick={(
          e,
          { index, numberOfItems, product, recommendationsTrackingUrl, strategy },
        ) => {
          trackProductClick(e, {
            ...trackingContext,
            recommendationsTrackingUrl,
            product: mapProductToTrackingProduct(product, currency),
            productIndex: index,
            numberOfItems,
            trackingTitle: `${strategy} : ${placementType}`,
          });
        }}
        onVisible={({ products, strategy }) => {
          trackProductImpression({
            ...trackingContext,
            products: products.map((product) => mapProductToTrackingProduct(product, currency)),
            trackingTitle: `${strategy} : ${placementType}`,
          });
        }}
        placementType={placementType}
        {...restOfProps}
      />
    </SectionContainer>
  );
};

export type { ProductCarouselRecommendationsProps };
