import React, { useContext } from 'react';

import { RecommendationsContext } from 'src/components/contexts/RecommendationsContext';
import type { ProductCarouselWithRecommendationsServiceDataProps } from './ProductCarouselWithRecommendationsServiceData';
import { ProductCarouselWithRecommendationsServiceData } from './ProductCarouselWithRecommendationsServiceData';

export interface ProductCarouselRecommendationsProps
  extends ProductCarouselWithRecommendationsServiceDataProps {
  placementType: string;
  sessionId?: string;
}

export const ProductCarouselRecommendations = ({
  currency,
  onNavigateCarousel,
  onProductClick,
  onVisible,
  placementType,
}: ProductCarouselRecommendationsProps) => {
  const { getPlacementData, loaded } = useContext(RecommendationsContext)!;
  const data = getPlacementData(placementType);
  const items = data?.items;
  const renderCarousel = items && items.length > 0;

  if (!loaded || (loaded && renderCarousel)) {
    return (
      <ProductCarouselWithRecommendationsServiceData
        currency={currency}
        items={items}
        onNavigateCarousel={onNavigateCarousel}
        onProductClick={onProductClick}
        onVisible={onVisible}
        placementTitle={data?.message}
        strategy={data?.strategy}
      />
    );
  } else {
    return null;
  }
};
