/* istanbul ignore file */

import { fetchJSONWithTimeout } from '@noths/polaris-client-utils';
import getConfig from 'next/config';

import type { NextConfig } from 'src/types/NextConfig';
import { logger } from 'src/utils/serverLogger';
import type { RecommendationsServiceProduct } from './types';

const {
  publicRuntimeConfig: { RECOMMENDED_PRODUCTS_API_URL },
} = getConfig() as NextConfig;

export const fetchRecommendedProducts = async (productIds: string[]) => {
  const idParamValues = productIds.join('&ids[]=');

  try {
    const response = await fetchJSONWithTimeout(
      `${RECOMMENDED_PRODUCTS_API_URL}?ids[]=${idParamValues}`,
    );
    const { products } = await response.json();

    return products as RecommendationsServiceProduct[];
  } catch (error) {
    logger.warn('failed to fetch data for recommended products:', { error: `${String(error)}` });
  }

  return null;
};
