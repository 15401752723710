import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type { TrackingContext } from 'src/types/TrackingContext';

export type CarouselTrackingDirection = 'next' | 'previous';

interface CarouselNavigationContext extends TrackingContext {
  numberOfItems: number;
}

export const trackCarouselNavigation = (
  swiped: boolean,
  direction: CarouselTrackingDirection,
  {
    numberOfItems,
    trackingContentType,
    trackingPosition,
    trackingTitle,
  }: CarouselNavigationContext,
) => {
  const navigationType = swiped ? 'Swipe' : 'Arrow click';
  sendGAEvent({
    event: 'custom_event',
    event_action: `${navigationType} ${direction}`,
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_label: `${trackingPosition} | ${trackingTitle} | ${numberOfItems}`.toLowerCase(),
    event_name: 'content_interaction',
    event_details: `${navigationType} ${direction}`.toLowerCase(),
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    total_options: `${numberOfItems}`,
  });
};
