import { sendGAEvent, sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';
import { getProductIdFromProductCode } from '@noths/polaris-client-utils';

import type { CatalogServiceProductData } from 'src/services/catalog/types';
import type { TrackingContext } from 'src/types/TrackingContext';

export type TrackingProductData = Pick<
  CatalogServiceProductData,
  | 'code'
  | 'freeDomesticDelivery'
  | 'isNew'
  | 'onSale'
  | 'salePercentage'
  | 'partner'
  | 'price'
  | 'purchasable'
  | 'title'
> & {
  linkURL?: string;
};

export interface ProductImpressionTrackingContext extends TrackingContext {
  pageType?: string;
  products: TrackingProductData[];
}

export interface ProductClickTrackingContext extends TrackingContext {
  numberOfItems: number;
  pageType?: string;
  product: TrackingProductData;
  productIndex: number;
  recommendationsTrackingUrl?: string;
}

const getTrackedProduct = (product: TrackingProductData, index: number, list?: string) => {
  const {
    code,
    freeDomesticDelivery,
    isNew,
    onSale,
    partner,
    price,
    purchasable,
    salePercentage,
    title,
  } = product;
  const formattedPrice = String(price.amount / 100);

  const dimension22List = [
    freeDomesticDelivery && 'free uk delivery',
    isNew && 'new',
    onSale && !!salePercentage && `-${salePercentage}% off`,
    !purchasable && 'out of stock',
  ]
    .filter(Boolean)
    .join(', ');

  const position = index + 1;

  return {
    name: title,
    id: String(getProductIdFromProductCode(code)),
    dimension22: dimension22List,
    dimension25: position,
    dimension30: String(code),
    dimension31: formattedPrice,
    price: formattedPrice,
    dimension32: formattedPrice,
    dimension33: onSale ? `${salePercentage}.0%` : 'none',
    brand: partner.name,
    dimension34: partner.shortcode,
    position,
    ...(list && { list }),
    dimension16: 'not set',
  };
};

export const trackProductImpression = ({
  pageType = '',
  products,
  trackingContentType,
  trackingPosition,
  trackingTitle,
}: ProductImpressionTrackingContext) => {
  const numberOfItems = products.length;
  const list = `${pageType && pageType + ' - '}${trackingTitle}`.toLowerCase();

  sendGAEvent({
    ecommerce: null,
  });

  sendGAEvent({
    event: 'product_impressions',
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_action: 'Product Impressions',
    event_label: `${trackingPosition} | ${trackingTitle}`.toLowerCase(),
    event_name: 'view_item_list',
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    total_options: `${numberOfItems}`,
    ecommerce: {
      impressions: products.map((product, index) => getTrackedProduct(product, index, list)),
    },
  });
};

export const trackProductClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  {
    numberOfItems,
    pageType = '',
    product,
    productIndex,
    recommendationsTrackingUrl,
    trackingContentType,
    trackingPosition,
    trackingTitle,
  }: ProductClickTrackingContext,
) => {
  sendGAEvent({
    ecommerce: null,
  });

  sendGALinkClickEvent(e, {
    event: 'product_clicks',
    event_category: `${trackingPosition} | ${trackingContentType}`,
    event_action: 'Product Click',
    event_label: `${trackingPosition} | ${trackingTitle}`.toLowerCase(),
    ...(recommendationsTrackingUrl && { algonomy_tracking_URL: `${recommendationsTrackingUrl}` }),
    event_name: 'select_item',
    content_position: `${trackingPosition}`,
    content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
    total_options: `${numberOfItems}`,
    selected_position: `${productIndex + 1}`,
    item_id: product.code.toString(),
    ecommerce: {
      click: {
        actionField: {
          list: `${pageType && pageType + ' - '}${trackingTitle}`.toLowerCase(),
        },
        products: [getTrackedProduct(product, productIndex)],
      },
    },
  });
};
