/* istanbul ignore file */

import React, { createContext } from 'react';
import type { RichRelevancePlacement } from '@noths/polaris-client-recommendations';

import type { PageConfiguration, PageContent, PageMetadata } from 'src/types/PageProps';
import { useRecommendations } from './useRecommendations';

export interface RecommendationsContextValue {
  getPlacementData: (placementType: string) => RichRelevancePlacement | null;
  loaded: boolean;
}

export const RecommendationsContext = createContext<RecommendationsContextValue | undefined>(
  undefined,
);

export interface RecommendationsContextProviderProps {
  children: React.ReactNode;
  pageConfig: PageConfiguration;
  pageContent: PageContent;
  pageMetadata: PageMetadata;
}

export const RecommendationsContextProvider = (props: RecommendationsContextProviderProps) => {
  const { children, pageConfig, pageContent, pageMetadata } = props;
  const contextValue: RecommendationsContextValue = useRecommendations({
    pageConfig,
    pageContent,
    pageMetadata,
  });

  return (
    <RecommendationsContext.Provider value={contextValue}>
      {children}
    </RecommendationsContext.Provider>
  );
};
