import { useEffect, useState } from 'react';
import { getProductIdFromProductCode } from '@noths/polaris-client-utils';

import { fetchRecommendedProducts } from 'src/services/recommendations/fetchRecommendedProducts';
import type { RecommendationsServiceProduct } from 'src/services/recommendations/types';
import type { RecommendedItem } from 'src/types/recommendations';

export interface RecommendedItemWithRecommendedProduct extends RecommendedItem {
  product: RecommendationsServiceProduct;
}

const mergeAndFilterRecommendationsServicedata = (
  products: RecommendationsServiceProduct[],
  items: RecommendedItem[],
): RecommendedItemWithRecommendedProduct[] =>
  items.reduce((filtered: RecommendedItemWithRecommendedProduct[], item: RecommendedItem) => {
    const product = products.find(
      ({ code }) => getProductIdFromProductCode(code) === Number(item.id),
    );

    if (product) {
      filtered.push({
        ...item,
        product,
      });
    }

    return filtered;
  }, []);

export const useRecommendationsServiceProductData = (items: RecommendedItem[]) => {
  const [products, setProducts] = useState([] as RecommendationsServiceProduct[]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getRecommendationsServiceProductData = async () => {
      if (items.length > 0) {
        const productIds = items.map(({ id }) => id);
        const products = (await fetchRecommendedProducts(productIds)) || [];

        setProducts(products);
        setLoaded(true);
      }
    };

    getRecommendationsServiceProductData();
  }, [items]);

  return { products: mergeAndFilterRecommendationsServicedata(products, items), loaded };
};
